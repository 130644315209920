import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import React from "react";
import { SummitAgendaPageQuery } from "../../../graphql-types";
import { Helmet } from "react-helmet";
import MenuBar from "../../components/MenuBar";
import PageContent from "../../components/PageContent";
import HeroBanner from "../../components/HeroBanner";
import { getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

const getSuffix = (day: number) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

const AgendaPage = () => {
  const agendaDates = useStaticQuery<SummitAgendaPageQuery>(query).agendaDates;
  const agendaPage = useStaticQuery<SummitAgendaPageQuery>(query).agendaPage;
  const heroImage = getImage(agendaPage?.agendaPageHeroImage?.localFile?.childImageSharp);

  const datesMap: { [key: string]: SummitAgendaPageQuery["agendaDates"]["edges"]["node"] } = {};
  for (const edge of agendaDates.edges) {
    const date = new Date(edge.node.date);

    const day = date.toLocaleString('en', {
      timeZone: 'UTC',
      weekday: "long",
      month: "long", day: "numeric"
    }) + getSuffix(date.getUTCDate());

    if (!datesMap[day]) datesMap[day] = [];
    datesMap[day].push(edge.node);
  }

  const generateRows = () => {
    const rows = [];
    for (const day in datesMap) {
      const dateHeader = <div className="text-orange text-lg font-bold mt-10" key={day}>{day}</div>
      rows.push(dateHeader)

      for (const event of datesMap[day]) {
        rows.push(
          <div className="flex w-full justify-between text-sm sm:text-base font-semibold my-5" key={event.time + ":" + event.event.data.childMarkdownRemark.rawMarkdownBody}>
            <div className="py-10 bg-off-white text-center w-[20%] flex items-center justify-center">
              <div className="text-center w-[90%]">
                {event.time}
              </div>
            </div>
            <ReactMarkdown 
            className="py-10 bg-off-white w-[40%] mx-2 sm:mx-5 flex items-center justify-center text-center flex-col"
              components={{ a: ({ node, ...props }) => <a target="_blank" style={{ cursor: "pointer", color: "#1592E6" }} {...props} /> }}
            >
              {event.event.data.childMarkdownRemark.rawMarkdownBody}
            </ReactMarkdown>
            <ReactMarkdown
              className="py-10 bg-off-white w-[40%] text-center flex items-center justify-center flex-col"
            >{event.location.data.childMarkdownRemark.rawMarkdownBody}</ReactMarkdown>
          </div>
        )

        for (const panelItem of event.eventPanels.sort((a, b) => new Date(a.date) - new Date(b.date))) {
          const speakers = panelItem.speakers.data.split("\n\n")
          let speakersList = []

          for (const speaker of speakers) {
            speakersList.push(<div>{speaker}</div>)
          }

          rows.push(
            <div className="flex w-full justify-between font-semibold text-sm sm:text-[16px] my-3" key={panelItem.title}>
              <div className="py-5 w-[3%] "></div>
              <div className="py-5 bg-off-white text-center w-[17%] flex items-center justify-center">
                <div className="text-center w-[90%]">
                  {panelItem.panelTime}
                </div>
              </div>
              <div className="py-5 bg-off-white w-[40%] mx-2 sm:mx-5 flex items-center justify-center">
                <div className="text-center w-full">
                  {panelItem.title}
                </div>
              </div>
              <div className="py-5 bg-off-white w-[40%] flex items-center justify-center flex-col font-sans text-center">
                <div>
                  {speakersList}
                </div>
              </div>
            </div>
          )
        }
      }
    }
    return rows;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Summit Agenda</title>
      </Helmet>
      <MenuBar />
      <HeroBanner image={heroImage} title={
        <div>SUMMIT<br></br>AGENDA</div>
      } summitPage={true} objectPosition={"right bottom"} />
      <PageContent className="pb-16 bg-white">
        {
          generateRows()
        }
      </PageContent>
    </>
  )
}

export default AgendaPage;


export const query = graphql`
query SummitAgendaPage {
    agendaDates: allStrapiSummitAgendaDate(sort: {fields: strapi_id, order: ASC}) {
      edges {
        node {
          time
          event {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          date
          location {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          eventPanels {
            title
            date(formatString: "") 
            panelTime
            speakers {
              data
            }
          }
        }
      }
    }
    agendaPage: strapiSummitAgendaPage {
      heroImageText
      agendaPageHeroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`;